<template>
  <div>
    <modal-card :title="'Site note'" :processing="processing">
      <b-field label="Note">
        <b-input
          v-model="noteCore.message"
          type="textarea"
          rows="4"
          placeholder="Add new site note"
        />
      </b-field>

      <b-checkbox v-if="user.isAgent" v-model="noteCore.isRestricted"
        >Restrict note to staff only</b-checkbox
      >

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!validForm"
        type="submit"
        class="button is-success"
        @click="submit"
      >
        {{ note ? "Update note" : "Add note" }}
      </button>
    </modal-card>
  </div>
</template>

<script>
import { serverTimestamp } from "@firebase/firestore";
export default {
  name: "NoteModal",
  props: {
    siteId: {
      type: String,
      required: true
    },
    noteId: {
      type: String,
      required: false,
      default: ""
    },
    note: {
      required: false,
      type: Object,
      default: null
    },
    defaultNote: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  data() {
    const note = {
      isRestricted: false,
      authorId: this.$store.getters["auth/userId"](),
      dateCreated: serverTimestamp(),
      dateUpdated: serverTimestamp(),
      message: "",
      authorName:
        this.$store.getters["user/name"]() ||
        this.$store.getters["user/email"]()
    };
    return {
      processing: false,
      loading: false,
      noteCore: this.$_.merge(
        {},
        note,
        this.note ? this.note : {},
        this.defaultNote
      )
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    validForm() {
      return !this.$_.isEmpty(this.$_.trim(this.noteCore.message));
    }
  },
  methods: {
    submit() {
      if (!this.loading && !this.processing && this.validForm) {
        this.processing = true;
        const action = this.note ? "sites/updateNote" : "sites/addNote";
        const payload = this.note
          ? {
              noteId: this.noteId,
              siteId: this.siteId,
              payload: this.noteCore
            }
          : {
              siteId: this.siteId,
              payload: this.noteCore
            };

        this.$store
          .dispatch(action, payload)
          .then(() => this.$emit("close"))
          .finally(() => (this.processing = false));
      }
    }
  }
};
</script>
